import axios from "axios";
import { CMS_API, CMS_API_TOKEN } from "./util/constant";

// TODO: rename this function
export const isAutheticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("Lapitas_token")) {
    return JSON.parse(localStorage.getItem("Lapitas_token"));
  } else {
    return false;
  }
};

export const getValue = (value) => {
  return (
    <span className="formatted_value fw-normal">
      {value == null || value === undefined || value === ""
        ? "Not Specified"
        : value}
    </span>
  );
};

export const getCMSEndpoint = (endpoint) => {
  return axios.get(`${CMS_API}/${endpoint}`, {
    headers: { Authorization: `Bearer ${CMS_API_TOKEN}` },
  });
};
