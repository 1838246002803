import { useState } from "react";
import CustomButton from "../Common/Button";
import Input from "../Common/Input";
import axios from "axios";
import { API } from "../../util/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function ForgotPassword() {
  return (
    <div>
      <div
        className="p-md-5  px-2"
        style={{
          textAlign: "center",
          margin: "auto",
        }}
      >
        <h2
          className="fw-normal secondary-font"
          style={{ marginBottom: "1rem" }}
        >
          Forgot Password
        </h2>
        <div
          className="px-md-5 my-2"
          style={{ width: "800px", maxWidth: "100%", margin: "auto" }}
        >
          <h5 className="fw-normal" style={{ marginBottom: "3rem", textAlign: "left" }}>
            Enter the account you used to create your account and we will send you an email containing password reset instructions.
          </h5>
          <RightForm />
        </div>
      </div>
    </div>
  );
}

const initialData = {
  email: ""
};

const RightForm = () => {
  const history = useHistory();
  const [values, setValues] = useState(initialData);

  const [error, setError] = useState("");

  const handleSubmit = () => {
    setError(null);
    const data = { ...values };
    let form_data = new FormData();

    for (var key in data) {
      if (data[key] === Object(data) || Array.isArray(data[key])) {
        for (var i in data[key]) {
          form_data.append(`${key}[${i}]`, data[key][i] || "");
        }
        if (Array.isArray(data[key]) && data[key].length === 0) {
          form_data.append(`${key}[0]`, "");
        }
      } else {
        form_data.append(key, data[key]);
      }
    }

    setValues(initialData);
    axios
      .post(`${API}/send-reset-password-email`, values)
      .then((_res) => {
        toast.success("Check your email", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        setError(error.response.data.error);
        toast.error("Failed to send email", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    history.push("/");
  };

  const handleChange = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const inputStyle = { background: "white", border: "1px solid" };
  return (
    <>
      <div className="px-3  px-sm-3">
        <form
          onSubmit={handleSubmit}
          style={{ width: "800px", maxWidth: "90%", margin: "auto" }}
        >
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="email"
              name="email"
              label="EMAIL"
              onChange={(e) => handleChange("email", e)}
              value={values.contact_email}
            />
          </div>

          <CustomButton
            type="submit"
            style={{ alignSelf: "center", width: "100px" }}
            onClick={handleSubmit}
          >
            Send
          </CustomButton>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
