import { useEffect, useState } from "react";
import { getCMSEndpoint } from "../../helper";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import "./BlogPost.css";

function BlogPost() {
  var [post, setPost] = useState(null);

  const [error, setError] = useState("");

  var { id } = useParams();

  function handleError(res) {
    setError(`Encountered error while loading posts: ${res.data.error}`);
  }

  useEffect(() => {
    getCMSEndpoint(`Posts/${id}`)
      .then((res) => {
        if (res.data.error) {
          handleError(error.response);
        } else {
          setPost(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error.response);
      });
  }, []);

  // Reformats from yyyy-mm-dd to dd/mm/yyyy
  function reformatDate(dateString) {
    var parts = dateString.split("-");
    parts.reverse();
    return parts.join("/");
  }

  return (
    <div
      className="p-3 p-md-5"
      style={{
        color: "black",
        width: "1100px",
        maxWidth: "95%",
        margin: "auto",
      }}
    >
      {post == null ? (
        <>
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <div>Loading post...</div>
          )}
        </>
      ) : (
        <div>
          <h3 id="title">{post.attributes.title}</h3>
          <p id="subtitle">
            Written by {post.attributes.author} -{" "}
            {reformatDate(post.attributes.date_published)}
          </p>
          <div id="markdown">
            <ReactMarkdown>{post.attributes.content}</ReactMarkdown>
          </div>
        </div>
      )}

      <p className="fw-normal mb-3"></p>
    </div>
  );
}

export default BlogPost;
