import React from "react";
import about from "../../images/lapitas-about-us.jpg";
import lapitasLogo from "../../images/Lapitas-org-logo.png";

function AboutUs() {
  return (
    <div
      className="p-3 p-md-5"
      style={{
        color: "black",
        width: "1100px",
        maxWidth: "95%",
        margin: "auto"
      }}
    >
      <div
        style={{
          textAlign: "center",
          width: "800px",
          maxWidth: "90%",
          margin: "auto",
        }}
      >
        <h2 className="fw-normal" style={{ marginBottom: "2rem" }}>
          About
        </h2>
        <img src={about} className="img-fluid" />
        <p
          className="primary-font"
          style={{ fontSize: "24px", marginTop: "2rem", marginBottom: "3rem" }}
        >
          <i>
            “We believe these treasured pieces cannot be valued by the sum total
            of their material parts. They hold the history and meaning of love,
            family and who we are.”
          </i>
        </p>
      </div>
      <i style={{ color: "#444", display: "block", marginBottom: "1rem" }}>
        <div className="fw-normal">Lapis (Latin) Meaning “Stone” </div>
        <div className="fw-normal">Veritas (Latin) Meaning “Truth”</div>
      </i>
      <p className="fw-normal mb-3">
        With the collective experience of over 50 years in the luxury jewellery
        industry; and offices spanning 3 continents, we are obsessed with the
        integrity and protection of diamonds, fine jewels, timepieces and gold.{" "}
      </p>
      <p className="fw-normal mb-3">
        We believe these treasured pieces cannot be valued by the sum total of
        their material parts. They hold the meaning and history of love, family
        and who we are.
      </p>
      <p className="fw-normal mb-3">
        Lapitas exists to authenticate ownership of precious goods worldwide.
        Helping lost & stolen items return home, no matter the distance of
        location or time.
      </p>
      <img src={lapitasLogo} style={{height: "5rem", marginTop: "1rem", marginBottom: "0.5rem"}}/>
      <p className="fw-normal primary-font" style={{ color: "#444" }}>
        <i>“Protecting Your Love, Legacy & Investment.”</i>
        <br />
        New York - Cambridge - Sydney
      </p>
    </div>
  );
}

export default AboutUs;
