import React from "react";
import { Link } from "react-router-dom";
import VerticalCard from "./../Common/VerticalCard";
import ScrollAnimation from "react-animate-on-scroll";
import CustomButton from "../Common/Button";

import { isAutheticated } from "../../helper";

function About() {
  return (
    <div className="py-4 my-5">
      <h2 className="text-center secondary-font subheading">
        Where Do I Find My Unique Number?
      </h2>
      <ScrollAnimation animateIn="fadeIn">
        <div className="container text-center ">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <VerticalCard
                image="https://images.squarespace-cdn.com/content/v1/60227ba39dbc57670b6c8a5b/1614516533204-4K4F8110PQI0SNQBP1ZM/Diamond+Number.jpg?format=500w"
                title="Diamonds"
                description="The unique serial number is laser inscribed on the girdle of the diamond. The same number is stated on the Certificate Report Number issued by the gemstone laboratory."
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <VerticalCard
                image="https://images.squarespace-cdn.com/content/v1/60227ba39dbc57670b6c8a5b/1614516798584-N6XT5NADBI4XC84QQX7O/cartier+serial+number.jpg?format=500w"
                title="Jewels"
                description="The unique serial number can be found engraved either near the karat stamp, hallmark or the brand/makers mark. The same number is stated on the Certificate of Authenticity issued by the jewellery brand."
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <VerticalCard
                image="https://images.squarespace-cdn.com/content/v1/60227ba39dbc57670b6c8a5b/1614516605668-I22BN7WFTADCVVNF9U1X/rolex+serial+number.jpg?format=500w"
                title="Watches"
                description="The unique serial numbers on watches can be found either between the lugs, inside the case or the case back. The same number is stated on the Certificate of Authenticity issued by the brand."
              />
            </div>
          </div>
          {isAutheticated() ? (
            <Link
              className="nav-link active"
              aria-current="page"
              to="/registrations"
            >
              <CustomButton
                uppercase={false}
                style={{ alignSelf: "center", marginTop: "1rem" }}
              >
                Register your items now
              </CustomButton>
            </Link>
          ) : (
            <Link
              className="nav-link active"
              aria-current="page"
              to="/register"
            >
              <CustomButton
                uppercase={false}
                style={{ alignSelf: "center", marginTop: "1rem" }}
              >
                Register your items now
              </CustomButton>
            </Link>
          )}
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default About;
