import React, { useEffect, useContext, useState } from "react";
import colors from "../../util/colors";
import { css } from "@emotion/css";
import CustomButton from "./../Common/Button";
import Icon from "../Common/Icon";
import ItemCard from "./../Common/ItemCard";
import { Link } from "react-router-dom";
import axios from "axios";
import { AppContext } from "./../../App";
import { API } from "../../util/constant";

function Registration() {
  const [data, setData] = useState([]);
  const [, setReloadData] = useState(false);
  var contextData = useContext(AppContext);
  const config = {
    headers: { Authorization: `Bearer ${contextData.token}` },
  };
  const getRegisteredItem = () => {
    if (contextData.isLoggedIn)
      axios
        .get(`${API}/items/${contextData.user._id}`, config)
        .then((res) => setData(res.data))
        .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    getRegisteredItem();
  }, []);

  return (
    <div>
      <div className="p-md-5 m-md-5 px-5 ">
        <div className="row justify-content-between">
          <div className="col-md-9 mx-auto">
            <div>
              <div style={{ marginTop: "2rem" }}>
                <h2 className="registrations-heading">Your Registrations</h2>
              </div>
              <div className={`py-3`}>
                <h5 className="fw-normal">
                  Total number of items {data?.length || 0}
                </h5>
                <Link
                  to="/register-lost-item"
                  style={{ color: colors.white, textDecoration: "none" }}
                >
                  <CustomButton type="small" uppercase={false} className="my-3">
                    <Icon>add</Icon> Add an Item
                  </CustomButton>
                </Link>
              </div>
              <div className={` py-1`}>
                {data.map((item, index) => (
                  <ItemCard
                    andleReload={() => setReloadData((prev) => !prev)}
                    number={index + 1}
                    item={item}
                    type={item.item_type}
                    value={item.value_of_item}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
