function ReturnToApp() {
  return (
    <div
      style={{
        padding: "1.5rem",
        paddingBottom: "6rem",
      }}
    >
      Payment has been completed. Click{" "}
      <a href="https://link.lapitas.org/return-to-app">
        here to return to the Lapitas app
      </a>
      .
    </div>
  );
}

export default ReturnToApp;
