import { useRef, useEffect } from "react";
import { toast } from "react-toastify";

function Payment({ name, onSuccess, onError, discountCode, fields, orderId, lapitasToken }) {
  const authorization = `Bearer ${lapitasToken ? lapitasToken : JSON.parse(localStorage.getItem("Lapitas_token")).token}`

  const createOrder = async (data, actions) => {
    if (orderId) { // Used for mobile payments
      return orderId;
    } else {
      const res = await fetch("/api/order/create", {
        method: "post",
        body: JSON.stringify({
          fields,
          discountCode
        }),
        headers: {
          Authorization: authorization,
          "Content-Type": "application/json"
        }
      });
      const orderData = await res.json();
      return orderData.id;
    }
  };

  const onApprove = async (data, actions) => {
    const res = await fetch("/api/order/" + data.orderID + "/complete", {
      method: "post",
      headers: {
        Authorization: authorization
      }
    });

    const orderData = await res.json();
    var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
    if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
      return actions.restart();
    }

    if (errorDetail) {
      var msg = "Sorry, your transaction could not be processed.";

      if (errorDetail.description) {
        msg += " " + errorDetail.description;
      }
      if (orderData.debug_id) {
        msg += " (" + orderData.debug_id + ")";
      }

      toast.error(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onError(msg);
      return;
    }

    onSuccess();
  };

  const paypal = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: createOrder,
        onApprove: onApprove,
        onShippingChange: () => {},
        onError: (err) => {
          onError(err);
        },
      })
      .render(paypal.current);
  });

  return <div ref={paypal}></div>;
}
export default Payment;
