import React from "react";
import styled from "@emotion/styled";
import colors from "../../util/colors";

export const CardImage = styled.img`
  width: 50%;
  margin-left: ${(props) => (props.moveLeft ? "-50px" : "0")};
  margin-right: ${(props) => (props.moveRight ? "-50px" : "0")};
  z-index: 1;
  height: 100%;
`;

const InformationCard = styled.div`
  width: 50%;
  padding: 4rem;
  z-index: 2;
  & > ul > li {
    font-weight: 600;
    font-size: 18px;
  }

  & > ul {
    margin-top: 1.2rem;
  }
`;

const HorizontalInfoCard = styled.div`
  display: flex;
  margin: 4rem 0;
  padding: 4rem 4rem;
  align-items: center;
  justify-content: center;
`;

const HorizontalInfoCardBlack = styled.div`
  display: flex;
  margin: 4rem 0;
  padding: 4rem 4rem;
  background: black;
  color: white;
`;

const imageLink =
  "https://images.squarespace-cdn.com/content/v1/60227ba39dbc57670b6c8a5b/1614516533204-4K4F8110PQI0SNQBP1ZM/Diamond+Number.jpg?format=500w";

export const Information = ({ title, description, children, emptySpace }) => {
  return (
    <InformationCard className="informationCard">
      <h2 className="secondary-font">{title}</h2>
      <br />
      {emptySpace && <br />}
      <p>{description}</p>
      {children}
    </InformationCard>
  );
};

function InfoCard({ image = imageLink, blackBackground = false, children }) {
  if (blackBackground) {
    return (
      <HorizontalInfoCardBlack className="horizontalInfoCard">
        {children}
      </HorizontalInfoCardBlack>
    );
  } else {
    return (
      <HorizontalInfoCard className="horizontalInfoCard">
        {children}
      </HorizontalInfoCard>
    );
  }
}

export default InfoCard;
