import { useState } from "react";
import CustomButton from "../Common/Button";
import Input from "../Common/Input";
import axios from "axios";
import { API } from "../../util/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import TextArea from "../Common/TextArea";

function Contact() {
  return (
    <div>
      <div
        className="p-md-5  px-2"
        style={{
          textAlign: "center",
          margin: "auto",
        }}
      >
        <h2
          className="fw-normal secondary-font"
          style={{ marginBottom: "1rem" }}
        >
          Contact
        </h2>
        <div
          className="px-md-5 my-2"
          style={{ width: "800px", maxWidth: "100%", margin: "auto" }}
        >
          <h3 className="fw-normal" style={{marginBottom: "3rem"}}>
            For General Enquiries & Assistance
          </h3>
          <RightForm />
        </div>
      </div>

      <div
        style={{
          color: "black",
          width: "1000px",
          maxWidth: "100%",
          margin: "auto",
          marginBottom: "5rem"
        }}
      >
        <div className="flex-column-mobile" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
          <div style={{marginRight: "2rem"}}>
            <div style={{marginBottom: "0.5rem"}}>
              General Enquiries:<br/>
              contact@lapitas.org
            </div>
            <div style={{marginBottom: "0.5rem"}}>
              Found Items:<br/>
              found@lapitas.org
            </div>
            <div style={{marginBottom: "0.5rem"}}>
              Press Enquiries:<br/>
              contact@lapitas.org
            </div>
          </div>
          <div>
            <div style={{marginBottom: "0.5rem"}}>
              US Office:<br/>
              newyork@lapitas.org
            </div>
            <div style={{marginBottom: "0.5rem"}}>
              UK Office:<br/>
              cambridge@lapitas.org
            </div>
            <div style={{marginBottom: "0.5rem"}}>
              AUSTRALIA Office:<br/>
              sydney@lapitas.org
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const initialData = {
  name: "",
  email: "",
  message: "",
};
const RightForm = () => {
  const history = useHistory();
  const [values, setValues] = useState(initialData);

  const [error, setError] = useState("");

  const handleSubmit = () => {
    setError(null);
    const data = { ...values };
    let form_data = new FormData();

    for (var key in data) {
      if (data[key] === Object(data) || Array.isArray(data[key])) {
        for (var i in data[key]) {
          form_data.append(`${key}[${i}]`, data[key][i] || "");
        }
        if (Array.isArray(data[key]) && data[key].length === 0) {
          form_data.append(`${key}[0]`, "");
        }
      } else {
        form_data.append(key, data[key]);
      }
    }

    toast.success("Email Received!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setValues(initialData);
    history.push("/");
    axios
      .post(`${API}/contact/sendEmail`, values)
      .then((res) => {})
      .catch((error) => {
        setError(error.response.data.error);
      });
  };

  const handleChange = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const inputStyle = { background: "white", border: "1px solid" };
  return (
    <>
      <div className="px-3  px-sm-3">
        <form
          onSubmit={handleSubmit}
          style={{ width: "800px", maxWidth: "90%", margin: "auto" }}
        >
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="text"
              name="name"
              label="NAME"
              onChange={(e) => handleChange("name", e)}
              value={values.firstname}
            />
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="email"
              name="email"
              label="EMAIL"
              onChange={(e) => handleChange("email", e)}
              value={values.contact_email}
            />
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <TextArea
              style={inputStyle}
              labelAlignment="left"
              type="text"
              name="message"
              label="MESSAGE"
              onChange={(e) => handleChange("message", e)}
              value={values.contact_email}
            />
          </div>

          <CustomButton
            type="submit"
            style={{ alignSelf: "center", width: "100px" }}
            onClick={handleSubmit}
          >
            Send
          </CustomButton>
        </form>
      </div>
    </>
  );
};
export default Contact;
