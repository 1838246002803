import React from "react";
import Button from "@material-ui/core/Button";
import styled from "@emotion/styled";
const TypeStyle = {
  big: { padding: "1.4em 1.9em" },
  small: { padding: "1em 1.9em" },
};

const StyledButton = styled.button`
  font-size: 16px;
  margin: 1rem 0;

  .material-icons {
    font-size: 14px;
  }

  background: black;
  padding: 10px 20px;
  border-width: 0;
  color: white;

  &:disabled {
    background: #aaa;
    color: #ddd;
  }
`;

function CustomButton({
  children,
  style,
  uppercase = true,
  disabled,
  ...props
}) {
  return (
    <StyledButton
      style={{
        textTransform: uppercase ? "uppercase" : "none",
        ...style,
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

export default CustomButton;
