import React, { useContext, useEffect, useState, Fragment } from "react";
import colors from "../../util/colors";
import CustomButton from "../Common/Button";
import styled from "@emotion/styled";
import Input from "../Common/Input";
import Dropdown from "./../Common/Dropdown";
import axios from "axios";
import { API } from "../../util/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AppContext } from "./../../App";
import { Label } from "./../Common/Input";
import Payment from "../Common/Payment";
import { FULL_PRICE } from "../../util/constant";

const FormHeader = styled.div`
  padding-bottom: 2rem;
  text-align: left;
  border-bottom: 1px solid ${colors.lightBlack};
  p {
    padding: 5px 0;
  }
  a {
    color: ${colors.black};
    text-decoration: none;
  }
`;

function RegisterLostItem({ match }) {
  return (
    <div>
      <div className="p-md-5 m-md-5 my-4">
        <div className="row justify-content-between">
          <div className="col-md-9 mx-auto">
            <RightForm itemId={match?.params?.itemId || false} />
          </div>
        </div>
      </div>
    </div>
  );
}

const initialData = {
  item_type: "",
  status: "Registered",
  diamond_colour: "",
  diamond_clarity: "",
  diamond_shape: "",
  diamond_laser_inscription: "",
  diamond_brand: "",
  jewel_colour: "",
  jewel_metal: "",
  jewel_materials: "",
  jewel_laser_inscription: "",
  jewel_brand: "",
  watch_metal: "",
  watch_serial_number: "",
  watch_brand: "",
  certificate_number: "",
  issuer_of_certificate: "",
  country_of_certificate_issuer: "",
  value_of_item: "",
};

const item_types = [
  { value: "Diamond", label: "Diamond" },
  { value: "Jewel", label: "Jewel" },
  { value: "Watch", label: "Watch" },
];

const item_statuses = [
  { value: "Registered", label: "Registered" },
  { value: "Lost", label: "Lost" },
  { value: "Sold", label: "For sale" },
];

const RightForm = ({ itemId }) => {
  const isEdit = itemId ? true : false;
  const getRegisteredItem = () => {
    axios
      .get(`${API}/item/${itemId}`, config)
      .then((res) => {
        const data = res.data;

        setValues(data);
      })
      .catch((err) => console.log({ err }));
  };
  useEffect(() => {
    if (isEdit) getRegisteredItem();
  }, []);

  const history = useHistory();
  const [values, setValues] = useState(initialData);
  const [checkoutNow, setCheckoutNow] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeSuccessful, setDiscountCodeSuccessful] = useState(false);
  const [price, setPrice] = useState(FULL_PRICE);
  const [checkoutButtonEnabled, setCheckoutButtonEnabled] = useState(false);

  const handleCheckoutButtonClick = () => {
    setCheckoutNow(true);
  };

  const validateForm = () => {
    if (values == null) {
      return false;
    }

    if (values.item_type == "" || values.item_type == null) {
      return false;
    }

    if (values.status == "" || values.status == null) {
      return false;
    }

    if (values.certificate_number == "" || values.certificate_number == null) {
      return false;
    }

    if (values.issuer_of_certificate == "" || values.issuer_of_certificate == null) {
      return false;
    }

    return true;
  };

  const [error, setError] = useState("");
  var contextData = useContext(AppContext);

  const config = {
    headers: { Authorization: `Bearer ${contextData.token}` },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEdit) {
      return;
    }
    setError(null);
    const data = { ...values };
    let form_data = new FormData();

    for (var key in data) {
      if (data[key] === Object(data) || Array.isArray(data[key])) {
        for (var i in data[key]) {
          form_data.append(`${key}[${i}]`, data[key][i] || "");
        }
        if (Array.isArray(data[key]) && data[key].length === 0) {
          form_data.append(`${key}[0]`, "");
        }
      } else {
        form_data.append(key, data[key]);
      }
    }

    axios
      .put(`${API}/item/${itemId}/${contextData.user._id}`, form_data, config)
      .then(() => {
        toast.success(
          isEdit
            ? "Item Updated Successfully!"
            : "Item Registered Successfully!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setValues(initialData);
        history.push("/registrations");
      })
      .catch((error) => {
        console.log({ error });
        setError(error.response.data.error);
      });
  };

  const handleChange = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  useEffect(() => {
    var value = validateForm();
    if (value != checkoutButtonEnabled) {
      setCheckoutButtonEnabled(value);
    }
  });

  return (
    <>
      <div className="p-md-5 m-md-5 px-5 ">
        <FormHeader>
          <h3>{isEdit ? "Edit Item" : "Register Item"}</h3>
        </FormHeader>

        <form id="form" className="py-3" onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div>
            <div className="row py-3">
              <div className="col-md-3"></div>
              <div className="col-md-9 register-certificate-input">
                <Label labelAlignment="right">Item Type <strong>(required)</strong></Label>
                <Dropdown
                  isDisabled={isEdit}
                  value={item_types.filter(
                    ({ value }) => value === values.item_type
                  )}
                  options={item_types}
                  onChange={(item) => {
                    handleChange("item_type", {
                      target: { value: item.value },
                    });
                  }}
                />
              </div>
            </div>
            {isEdit && (
              <div className="row py-3">
                <div className="col-md-3"></div>
                <div className="col-md-9 register-certificate-input">
                  <Label labelAlignment="right">Item Status</Label>
                  <Dropdown
                    value={item_statuses.filter(
                      ({ value }) => value === values.status
                    )}
                    options={item_statuses}
                    onChange={(item) => {
                      handleChange("status", {
                        target: { value: item.value },
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {/* Gem fields */}
          {values?.item_type === "Diamond" && (
            <>
              <div className="row py-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="diamond_colour"
                    label="Diamond Colour"
                    onChange={(e) => handleChange("diamond_colour", e)}
                    value={values.diamond_colour}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="diamond_clarity"
                    label="Diamond Clarity"
                    onChange={(e) => handleChange("diamond_clarity", e)}
                    value={values.diamond_clarity}
                  />
                </div>
              </div>
              <div className="row py-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="diamond_shape"
                    label="Diamond Shape/Cut"
                    onChange={(e) => handleChange("diamond_shape", e)}
                    value={values.diamond_shape}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="diamond_laser_inscription"
                    label="Diamond Laser Inscription"
                    onChange={(e) =>
                      handleChange("diamond_laser_inscription", e)
                    }
                    value={values.diamond_laser_inscription}
                  />
                </div>
              </div>
              <div className="row py-3">
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="diamond_brand"
                    label="Diamond Brand"
                    onChange={(e) => handleChange("diamond_brand", e)}
                    value={values.diamond_brand}
                  />
                </div>
              </div>
            </>
          )}
          {/* //Jewel fields */}
          {values?.item_type === "Jewel" && (
            <>
              <div className="row py-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="jewel_colour"
                    label="Jewel Colour"
                    onChange={(e) => handleChange("jewel_colour", e)}
                    value={values.jewel_colour}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="jewel_metal"
                    label="Jewel Metal"
                    onChange={(e) => handleChange("jewel_metal", e)}
                    value={values.jewel_metal}
                  />
                </div>
              </div>
              <div className="row py-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="jewel_materials"
                    label="Jewel Materials"
                    onChange={(e) => handleChange("jewel_materials", e)}
                    value={values.jewel_materials}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="jewel_laser_inscription"
                    label="Jewel Serial Number/Jewel Laser Inscription"
                    onChange={(e) => handleChange("jewel_laser_inscription", e)}
                    value={values.jewel_laser_inscription}
                  />
                </div>
              </div>
              <div className="row py-3">
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="jewel_brand"
                    label="Jewel Brand"
                    onChange={(e) => handleChange("jewel_brand", e)}
                    value={values.jewel_brand}
                  />
                </div>
              </div>
            </>
          )}
          {/* //watch fields */}
          {values?.item_type === "Watch" && (
            <>
              <div className="row py-3">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="watch_metal"
                    label="Watch Metal"
                    onChange={(e) => handleChange("watch_metal", e)}
                    value={values.watch_metal}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="watch_serial_number"
                    label="Watch Serial Number"
                    onChange={(e) => handleChange("watch_serial_number", e)}
                    value={values.watch_serial_number}
                  />
                </div>
              </div>
              <div className="row py-3">
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <Input
                    type="text"
                    name="watch_brand"
                    label="Watch Brand"
                    onChange={(e) => handleChange("watch_brand", e)}
                    value={values.watch_brand}
                  />
                </div>
              </div>
            </>
          )}
          {/* //common fields */}
          <div className="row py-3">
            <div className="col-md-3"></div>
            <div className="col-md-9 register-certificate-input">
              <Input
                type="text"
                name="certificate_number"
                label={<div>Certificate Number, Valuation Number, or Other Unique Number assigned to item <strong>(required)</strong></div>}
                onChange={(e) => handleChange("certificate_number", e)}
                value={values.certificate_number}
              />
            </div>
          </div>
          <div className="row py-3">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <Input
                type="text"
                name="issuer_of_certificate"
                label={<div>Issuer of Certificate, Valuation and Other Unique Number <strong>(required)</strong></div>}
                onChange={(e) => handleChange("issuer_of_certificate", e)}
                value={values.issuer_of_certificate}
              />
            </div>
          </div>
          <div className="row py-3">
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <Input
                type="text"
                name="country_of_certificate_issuer"
                label="Country of the Issuer of Certificate, Valuation and Other Unique Number"
                onChange={(e) =>
                  handleChange("country_of_certificate_issuer", e)
                }
                value={values.country_of_certificate_issuer}
              />
            </div>
          </div>
          <div className="row py-3">
            <div className="col-md-3"></div>

            <div className="col-md-9">
              <Input
                type="text"
                name="value_of_item"
                label="Purchase Price"
                onChange={(e) => handleChange("value_of_item", e)}
                value={values.value_of_item}
                mandatory
              />
            </div>
          </div>
          {!isEdit && values.checkout && (
            <div className="row py-3">
              <div className="col-md-3"></div>
              <div className="col-md-9"> Payment Successful</div>
            </div>
          )}
          {!isEdit && !values.checkout && (
            <div className="row py-3">
              <div className="col-md-3"></div>

              <div className="col-md-9">
                {checkoutNow ? (
                  <Payment
                    name={`${values.item_type}-${
                      values.certificate_number || "00"
                    }`}
                    onSuccess={() => {
                      toast.success("Item registered successfully!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      history.push("/registrations");
                    }}
                    onError={(msg) => {
                      toast.error(msg, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                    discountCode={discountCodeSuccessful ? discountCode : null}
                    fields={values}
                  />
                ) : (
                  <Fragment>
                    {discountCodeSuccessful ? (
                      <h4 className="secondary-font" style={{ color: "green" }}>
                        {discountCode} Code Applied
                      </h4>
                    ) : (
                      <div className="col-md-12" style={{ display: "flex" }}>
                        <div
                          className="col-md-8"
                          style={{ marginRight: "20px" }}
                        >
                          <Input
                            id="discount-code-input"
                            type="text"
                            placeholder="Discount code"
                            value={discountCode}
                            onChange={({ target }) =>
                              setDiscountCode(target.value)
                            }
                          />
                        </div>
                        <div>
                          <CustomButton
                            style={{ margin: "0", height: "100%" }}
                            onClick={() => {
                              axios
                                .get(
                                  `/api/order/discount-code/check?code=${discountCode}`
                                )
                                .then((res) => {
                                  return res.data;
                                })
                                .then(({ valid, value }) => {
                                  if (valid) {
                                    toast.success(
                                      "Discount code successfully applied"
                                    );
                                    setPrice(value);
                                    setDiscountCodeSuccessful(true);
                                  } else {
                                    toast.error("Invalid discount code");
                                  }
                                })
                                .catch((err) => {
                                  console.log(err);
                                  toast.error(
                                    "Failed to validate discount code"
                                  );
                                });
                            }}
                          >
                            Apply
                          </CustomButton>
                        </div>
                      </div>
                    )}

                    <br />
                    <CustomButton
                      style={{
                        alignSelf: "center",
                        width: "50%",
                        height: "55px",
                      }}
                      onClick={handleCheckoutButtonClick}
                      disabled={!checkoutButtonEnabled}
                    >
                      Checkout Now: ${price}
                    </CustomButton>
                  </Fragment>
                )}
              </div>
            </div>
          )}

          {isEdit && (
            <div className="row py-3">
              <div className="col-md-3"></div>
              <div className="col-md-9">
                <CustomButton
                  type="submit"
                  style={{
                    alignSelf: "center",
                    maxWidth: "300px",
                    width: "calc(100%)",
                    margin: "auto",
                    height: "120%",
                  }}
                  onClick={handleSubmit}
                  disabled={!checkoutButtonEnabled}
                >
                  Update Item
                </CustomButton>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default RegisterLostItem;
