import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { AppContext } from "./../../App";
import lapitasLogo from "../../images/Lapitas-org-logo.png";

const Container = styled.div`
  background: black;
  color: white;

  h4 {
    font-size: 1.2rem;
    font-weight: normal;
    padding: 3px 0;
    margin-top: 1rem;
  }

  p {
    font-size: calc((1 - 1) * 1.2vw + 1rem);
  }

  h5 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 1.4rem;
  }

  .link {
    text-decoration: none;
    color: black;
    font-size: calc((1 - 1) * 1.2vw + 1rem);
    margin: 0.8rem 0;
    display: block;
  }

  .icons {
    i {
      font-size: 18px;
      margin-right: 1rem;
    }
  }

  .locations {
    font-size: 0.8rem;
  }

  position: absolute;
  bottom: 0;
  width: 100%;
`;

function Footer() {
  const contextData = useContext(AppContext);
  const { isLoggedIn } = contextData;
  return (
    <Container>
      <div
        className="p-5 secondary-font"
        style={{ background: "#D9D9D9", color: "black" }}
      >
        <div className="row">
          <div className="col-md-6">
            <img src={lapitasLogo} style={{height: "4rem"}}/>
            <h4 className="primary-font">The Diamond, Jewel & Watch Registry</h4>
            <p className="locations">New York - Cambridge - Sydney </p>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <h5>Lapitas</h5>
                <Link className="link" to="/about-us">
                  About
                </Link>

                <Link className="link" to="/privacy">
                  Privacy
                </Link>
                <Link className="link" to="/legal">
                  Legal
                </Link>
                <Link className="link" to="/press">
                  Press
                </Link>
                <Link className="link" to="/contact">
                  Contact
                </Link>
              </div>
              <div className="col-md-4">
                <h5>INFORMATION</h5>
                <Link className="link" to="/search-list">
                  Search Database
                </Link>
                <Link className="link" to="/lost-and-found">
                  Lost & Found
                </Link>
              </div>
              <div className="col-md-4">
                <h5>SIGN UP</h5>
                {!isLoggedIn && (
                  <Link className="link" to="/register">
                    Create Account
                  </Link>
                )}
                {isLoggedIn && (
                  <Link className="link" to="/register-lost-item">
                    Register Your Item
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Footer;
