import React, { useState } from "react";
import styled from "@emotion/styled";
import SearchIcon from "@material-ui/icons/Search";
import colors from "../../util/colors";
import Button from "../Common/Button";
import { useHistory } from "react-router";

import lapitasLogoImage from "../../images/Lapitas-org-logo.png";
import backgroundLeftImage from "../../images/lapitas-side-watch.jpg";
import backgroundRightImage from "../../images/lapitas-side-diamond.jpg";

const BannerContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 5rem;
`;

const SearchInput = styled.input`
  border: none;
  &:focus {
    background-color: transparent;
    border-color: none;
    box-shadow: none;
  }
`;

const SearchInputContainer = styled.div`
  border: 0.5px solid black;
  padding: 0.5rem;
  align-items: center;
  margin: 1rem 0;

  &:focus-within {
    margin: 1rem;
    border-color: none;
    box-shadow: none;
    outline: none;
    &:after {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      bottom: -5px;
      right: -5px;
      border: 1px ${colors.black} solid;
    }
  }
`;

function Banner() {
  const history = useHistory();

  const [text, setText] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/search-list",

      state: { text: text },
    });
  };
  return (
    <div className="banner" style={{position: "relative"}}>
      <img style={{position: "absolute", height: "100%", top: "0", left: "0", transform: "translateX(-16%)"}} src={backgroundLeftImage} className="desktop-only"/>
      <img style={{position: "absolute", height: "100%", top: "0", right: "0", transform: "translateX(16%)"}} src={backgroundRightImage} className="desktop-only"/>
      <BannerContainer className="py-5  text-center">
        <img
          style={{ width: "200px", marginBottom: "1rem" }}
          src={lapitasLogoImage}
        />
        <h4 className="primary-font">The Diamond, Jewel & Watch Registry</h4>{" "}
        <form style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }} onSubmit={handleSubmit}>
          <SearchInputContainer className="input-group mb-3">
            <SearchIcon />
            <SearchInput
              value={text}
              onChange={(e) => setText(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Enter Number"
            />
          </SearchInputContainer>
          <Button
            type="submit"
            onClick={handleSubmit}
            style={{ width: "120px", marginTop: "0" }}
          >
            Search
          </Button>
        </form>
        <h6 className="secondary-font">
          CHECK THE OWNERSHIP STATUS
        </h6>
        <h6 className="secondary-font" style={{marginBottom: "1rem"}}>
          SEARCH FOR LOST & STOLEN
        </h6>
        <h5 className="primary-font">
          Protecting Your Love, Legacy & Investment
        </h5>
      </BannerContainer>
    </div>
  );
}

export default Banner;
