import React from "react";
import VerticalCard from "./../Common/VerticalCard";
import Button from "../Common/Button";
import ScrollAnimation from "react-animate-on-scroll";

import giaReportImage from "../../images/gia-report.jpg";
import smileyFaceImage from "../../images/smiley-face.jpg";
import securityImage from "../../images/lifetime-security.jpg";

import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const currentTab = (history, path) => {
  if (history.location.pathname === path) {
    return { borderBottom: "1px solid #000" };
  } else {
    return { color: "#000" };
  }
};

function Process() {
  let history = useHistory();

  return (
    <div className="py-4 my-5">
      <h2 className="text-center secondary-font subheading">
        How to Register Your Item
      </h2>
      <ScrollAnimation animateIn="fadeIn">
        <div className="container text-center ">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <VerticalCard
                image={smileyFaceImage}
                title="Create Your Free Account"
                description="Sign up for your free account. All of your registered items will be forever linked to your email address. In the event of loss or theft, wherever you are in the world, your item can be traced back to you. "
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <VerticalCard
                image={giaReportImage}
                title="Add Your Diamond, Jewel or Watch Details"
                description="Complete the online registration form and add your unique serial number. Upload photos, images of certificates and valuations. A secure place to keep digital copies of your documents forever."
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <VerticalCard
                image={securityImage}
                title="Single Payment for Lifetime Protection."
                description="Pay a single fee for lifetime registration. Your registration is secured by blockchain and your data privacy is protected. Flag your item as lost or stolen at anytime and transfer registration when you sell."
              />
            </div>
          </div>
          <Link
            style={currentTab(history, "/registrations")}
            className="nav-link active"
            aria-current="page"
            to="/register"
          >
            <Button uppercase={false} style={{ alignSelf: "center", marginTop: "2rem" }}>
              Create Your Free Account
            </Button>
          </Link>
        </div>
      </ScrollAnimation>
    </div>
  );
}

export default Process;
