import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Payment from "../Common/Payment";
import queryString from "query-string";

function MobilePayment() {
  const history = useHistory();

  const params = queryString.parse(window.location.search);
  const orderId = params["orderId"];
  const lapitasToken = params["lapitasToken"];

  var initialError = null;
  if (!orderId) {
    initialError = "Missing 'orderId' parameter";
    history.push("/");
  }

  const [error, setError] = useState(initialError);
  const [paymentComplete, setPaymentComplete] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "2.5rem",
        paddingBottom: "5rem",
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      }}
    >
      {paymentComplete ? (
        <div>
          Payment has been completed successfully. Please return to the Lapitas
          mobile app.
        </div>
      ) : (
        <>
          {error ? (
            <div>{error}</div>
          ) : (
            <div>
              <div style={{ marginBottom: "0.5rem" }}>
                Please select a payment method
              </div>
              <Payment
                onSuccess={() => {
                  toast.success("Item registered successfully!", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  window.location.href = "https://www.lapitas.org/return-to-app";
                }}
                onError={(msg) => {
                  toast.error(msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setError(
                    <div>
                      Payment failed, please{" "}
                      <a href="https://lapitas.org/return-to-app">
                        return to the app
                      </a>{" "}
                      and try again. If the issue persists please contact
                      Lapitas support (contact@lapitas.org)
                    </div>
                  );
                }}
                orderId={orderId}
                lapitasToken={lapitasToken}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MobilePayment;
