import React from "react";
import styled from "@emotion/styled";
const CardImage = styled.img`
  width: 100%;
`;

const imageLink =
  "https://images.squarespace-cdn.com/content/v1/60227ba39dbc57670b6c8a5b/1614516533204-4K4F8110PQI0SNQBP1ZM/Diamond+Number.jpg?format=500w";

function VerticalCard({ image = imageLink, title, description }) {
  return (
    <div style={{paddingTop: "3rem", paddingBottom: "0.5rem"}}>
      <CardImage src={image} className="img-fluid desktop-and-tablet" />
      <h3 className="text-center secondary-font" style={{marginTop: "2rem", marginBottom: "0.5rem"}}>{title}</h3>
      <p className="text-center secondary-font">{description}</p>
    </div>
  );
}

export default VerticalCard;
