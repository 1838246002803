import React from "react";

import { Link } from "react-router-dom";
import { isAutheticated } from "../../helper";
import styled from "@emotion/styled";

import Banner from "./../Screens/Banner";
import About from "../Screens/About";
import Process from "../Screens/Process";
import InfoCard, { CardImage, Information } from "../Common/InfoCard";
import CustomButton from "../Common/Button";
import EmailSignUpForm from "../Screens/EmailSignUpForm";

import protectyourloveImage from "../../images/lapitas-engagement-diamond-ring-registration.jpg";
import legacyWatchesImage from "../../images/lapitas-watch-registration.jpg";
import beautifulStrangerImage from "../../images/lapitas-lost-and-found-concierge.jpg";
import laplandMembershipImage from "../../images/lapitas-lapland-club.jpg";
import safeguardImage from "../../images/lapitas-argyle-investment-diamonds.jpg";
import quoteImage from "../../images/quote-image.jpg";

const TextBanner = styled.div`
  width: 100%;
  color: white;
  padding: 6rem 13rem;
  text-align: center;
  background: black;

  @media (max-width: 720px) {
    & {
      padding: 4rem 4rem;
    }
  }
`;

const CustomContainer = styled.div`
  max-width: 100% !important;
  width: 100%;
  padding: 0;
  margin: 0;

  .smallScreen {
    display: none;
  }

  @media (max-width: 720px) {
    .horizontalInfoCard {
      display: block;
    }
    .bigScreen {
      display: none;
    }
    .smallScreen {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
    }
    .informationCard {
      width: 100%;
    }
  }
`;

const getRegisterLink = (
  invert = false,
  customLabel = null,
  customURL = null
) => {
  var styles = { alignSelf: "center" };
  if (invert) {
    styles["background"] = "white";
    styles["color"] = "black";
  } else {
    styles["background"] = "black";
    styles["color"] = "white";
  }

  var label = customLabel == null ? "REGISTER NOW" : customLabel;

  return isAutheticated() ? (
    <Link
      className="active"
      aria-current="page"
      to={customURL == null ? "/registrations" : customURL}
    >
      <CustomButton style={styles}>{label}</CustomButton>
    </Link>
  ) : (
    <Link
      className="active"
      aria-current="page"
      to={customURL == null ? "/register" : customURL}
    >
      <CustomButton style={styles}>{label}</CustomButton>
    </Link>
  );
};

function Home() {
  return (
    <>
      <Banner />
      <TextBanner>
        <h4
          className="secondary-font"
          style={{ width: "800px", maxWidth: "90%", margin: "auto" }}
        >
          The First Ownership Authentication Platform Built on Blockchain
          Technology to Protect Your Most Treasured Items Worldwide.
          <br />
          <br />
          Returning Lost & Stolen Luxury Goods to Owners No Matter the Distance
          of Location or Length of Time.
        </h4>{" "}
      </TextBanner>
      <Process />
      <img
        src={quoteImage}
        style={{ width: "100%", height: "400px", objectFit: "cover" }}
      />
      <TextBanner>
        <div style={{ width: "900px", maxWidth: "90%", margin: "auto" }}>
          <h2
            className="pb-4 fw-normal secondary-font quote"
            style={{ lineHeight: "50.5px" }}
          >
            "I’m so relieved to know wherever my diamond is lost, it can find
            its way back to me."
          </h2>
          <br />
          <span
            style={{ fontSize: "20px" }}
            className="secondary-font quote-author"
          >
            — Jessica Fuentes (Owner of a 1.20ct Diamond Engagement Ring)
          </span>
        </div>
      </TextBanner>
      <About />

      <div className="container">
        <img
          style={{ width: "100%" }}
          className="py-2 img-fluid"
          src="https://images.squarespace-cdn.com/content/v1/60227ba39dbc57670b6c8a5b/1629730596003-Z9GW26DL512BCQSIN54G/logos.png?format=1000w"
        />
        <h3
          className="py-3 fw-normal text-center secondary-font"
          style={{ width: "900px", margin: "auto", maxWidth: "90%" }}
        >
          Lapitas registers any registration number, certificate number and
          laser inscription from any brand, gem laboratory or institution.
        </h3>
      </div>
      <CustomContainer className="container concierge-section">
        <InfoCard blackBackground={true}>
          <Information
            title="A First Class Lost & Found Concierge Service"
            description="We make it easy to reunite precious items to their owners. Found an item and want to return it back home? Book a secure pick up today. We arrange free worldwide secure pick up & delivery at no cost to the finder or the owner."
          >
            <ul>
              <li style={{ color: "white !important" }}>
                Free secure pick up & delivery service.
              </li>
              <li style={{ color: "white !important" }}>
                We provide the thank you reward.
              </li>
            </ul>
            {getRegisterLink(true, "BOOK A PICK UP", "/lost-and-found#root")}
          </Information>
          <CardImage
            src={beautifulStrangerImage}
            className="img-fluid smallScreen"
          />
          <CardImage
            src={beautifulStrangerImage}
            className="img-fluid bigScreen"
          />
        </InfoCard>
      </CustomContainer>
      <CustomContainer className="container">
        <InfoCard>
          <Information
            title="Protect Your Love"
            description="Help your lost item find you. Lapitas is the only database that can help return your engagement ring back to you, from anywhere in the world."
          >
            <ul>
              <li style={{ color: "white !important" }}>
                Stop your lost/stolen diamond from being illegally sold or
                trafficked.
              </li>
              <li style={{ color: "white !important" }}>
                Free secure return delivery service from anywhere in the world.
              </li>
            </ul>
            {getRegisterLink()}
          </Information>
          <CardImage
            src={protectyourloveImage}
            className="img-fluid smallScreen info-image"
          />
          <CardImage
            src={protectyourloveImage}
            className="img-fluid bigScreen info-image"
          />
        </InfoCard>
      </CustomContainer>
      <CustomContainer className="container">
        <InfoCard>
          <Information
            title="Your Legacy, Secured by Blockchain."
            description="Luxury timepieces are worn and appreciated for generations. Help to keep them in the family forever. Assure provenance and legitimate ownership by linking your watches unique serial number to your details."
          >
            <ul>
              <li style={{ color: "white !important" }}>
                Link your email to your unique serial number.
              </li>
              <li style={{ color: "white !important" }}>
                No annual renewals, no subscription fees.
              </li>
            </ul>
            {getRegisterLink()}
          </Information>

          <CardImage
            src={legacyWatchesImage}
            className="img-fluid smallScreen info-image"
          />
          <CardImage
            src={legacyWatchesImage}
            className="img-fluid bigScreen info-image"
          />
        </InfoCard>
      </CustomContainer>
      <CustomContainer className="container">
        <InfoCard>
          <Information
            title="Safeguard Your Investment."
            description="Important investment-grade Argyle diamonds hold a vast amount of wealth within a small measure of space. Protect your investment and ownership legitimacy by registering your Argyle diamonds laser inscription and certificate number."
          >
            <ul>
              <li style={{ color: "white !important" }}>
                Increased protection.
              </li>
              <li style={{ color: "white !important" }}>
                Transfer registration when you sell.
              </li>
            </ul>
            {getRegisterLink()}
          </Information>

          <CardImage src={safeguardImage} className="img-fluid smallScreen" />
          <CardImage src={safeguardImage} className="img-fluid bigScreen" />
        </InfoCard>
      </CustomContainer>
      <CustomContainer className="container">
        <InfoCard>
          <Information
            title="LAPLAND: Exclusive Membership & Rewards"
            description="Registering with Lapitas gives you instant access to LAPLAND: The Luxury Rewards Club. Our partnerships give you access to next-level services, giveaways and exclusive offers. Registering with Lapitas gives you instant access to LAPLAND: The Luxury Rewards Club."
          ></Information>
          <CardImage
            src={laplandMembershipImage}
            className="img-fluid smallScreen"
          />
          <CardImage
            src={laplandMembershipImage}
            className="img-fluid bigScreen"
          />
        </InfoCard>
      </CustomContainer>
      <CustomContainer>
        <EmailSignUpForm />
      </CustomContainer>
    </>
  );
}

export default Home;
