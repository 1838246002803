import React from "react";

function Press() {
  return (
    <div className="p-3 p-md-5" style={{ color: "black" }}>
      <div className="mx-md-5 px-md-5">
        <div className="mx-md-5 px-md-5">
          <h2 className="fw-normal text-center my-5">Press</h2>

          <p className="fw-normal  mb-4">
            Find the latest press releases from Lapitas, and related material
            such as press kit, company information and images library.
          </p>
          <p className="fw-normal  mb-4">
            For any media inquiries, please reach out to press@lapitas.org
          </p>
        </div>
      </div>
    </div>
  );
}

export default Press;
