import Input, { Label } from "../Common/Input";
import CustomButton from "../Common/Button";
import { useEffect } from 'react';

function EmailSignUpForm() {
  // Workaround to include inline script in component
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", "https://js.createsend1.com/javascript/copypastesubscribeformlogic.js");
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  });

  return (
    <div
      style={{
        width: "100%",
        paddingTop: "0",
        paddingBottom: "2rem",
      }}
    >
      <div
        className="secondary-font email-sign-up"
        style={{
          maxWidth: "900px",
          width: "calc(100% - 4rem)",
          margin: "auto",
          marginBottom: "6rem",
        }}
      >
        <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>
          Sign up for News &amp; Exclusive Offers
        </h2>
        <form
          className="js-cm-form"
          id="subForm"
          action="https://www.createsend.com/t/subscribeerror?description="
          method="post"
          data-id="2BE4EF332AA2E32596E38B640E90561955989C5115D9775DFEBE1946CCC1A28ED90F1A5197FB819EF3B162FD8AED0C5AFB1FE65BDAE044B71A334342A7135860"
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input
              style={{ display: "inline", width: "80%", border: "2px solid black" }}
              autocomplete="Email"
              aria-label="Email"
              className="js-cm-email-input qa-input-email"
              id="fieldEmail"
              maxlength="200"
              name="cm-jthkjkk-jthkjkk"
              required=""
              placeholder="Email address"
              type="email"
            />
            <button
              style={{
                padding: "0.5rem 1rem",
                display: "block",
                height: "100% !important",
                backgroundColor: "black",
                border: "none",
                color: "white",
                width: "calc(20% - 1rem)",
                marginLeft: "1rem",
                textTransform: "upper",
                fontFamily: "sans-serif"
              }}
              type="submit"
            >
              SIGN UP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmailSignUpForm;
