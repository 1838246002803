import React, { useContext, useRef } from "react";
import CustomButton from "./Button";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import colors from "../../util/colors";
import { getValue } from "../../helper";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../util/constant";
import { AppContext } from "../../App";
import { toast } from "react-toastify";

const ImgContainer = styled.div`
  padding-top: 1rem;
  img {
    width: 300px;
    margin-right: 2rem;
  }
`;

const borderBottom = css`
  border-bottom: 0.5px solid ${colors.gray};
`;

const Container = styled.div`
  .formatted_value {
    color: #505050;
  }
`;

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
}

function ItemCard({ number, type, value, item, handleReload }) {
  const hiddenFileInput = useRef(null);
  const hiddenFileInputJewel = useRef(null);
  var contextData = useContext(AppContext);
  const config = {
    headers: { Authorization: `Bearer ${contextData?.token}` },
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleJewelImageClick = (event) => {
    hiddenFileInputJewel.current.click();
  };
  const handleChange = (event) => {
    var formData = new FormData();
    formData.append("files", JSON.stringify(item.files));

    if (event) {
      const files = event.target.files;

      for (const key of Object.keys(files)) {
        if (event.target.name === "primaryPhoto") {
          formData.append("primaryPhoto", files[key]);
        } else {
          formData.append("newFiles", files[key]);
        }
      }
    }

    const loadingToastId = toast.loading("Item being updated, dont refresh!", {
      position: "top-center",
      autoClose: 1000,
      closeOnClick: true,
    });

    axios
      .put(`${API}/item/${item._id}/${contextData.user._id}`, formData, config)
      .then((res) => {
        toast.dismiss(loadingToastId);
        toast.success(`File ${event ? "Uploaded" : "Deleted"} Successfully!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload(false);
      })
      .catch((error) => {
        toast.dismiss(loadingToastId);
        toast.error("Failed to upload files, try again after some time!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log({ error });
      });
  };

  return (
    <Container className={`${borderBottom} py-5`}>
      <h5 className="fw-normal">Item #{number}</h5>
      <div className="d-flex my-3">
        <Link
          to={`/edit-item/${item._id}`}
          style={{ color: colors.white, textDecoration: "none" }}
        >
          <CustomButton type="small" uppercase={false}>
            Edit Item Details
          </CustomButton>
        </Link>
        <CustomButton
          onClick={handleClick}
          type="small"
          uppercase={false}
          className="mx-3"
        >
          Upload Documents
        </CustomButton>
        <CustomButton
          onClick={handleJewelImageClick}
          type="small"
          uppercase={false}
          className="mx-3"
        >
          Upload Jewel Image
        </CustomButton>
        <input
          type="file"
          ref={hiddenFileInputJewel}
          onChange={handleChange}
          name="primaryPhoto"
          style={{ display: "none" }}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          name="photo"
          style={{ display: "none" }}
          multiple
        />
      </div>
      <div className="my-3">
        <h5 className="fw-normal">Item Type: {getValue(type)} </h5>
        <h5 className="fw-normal">Status: {getValue(item?.status)} </h5>
        {type === "Diamond" && (
          <>
            <h5 className="fw-normal">
              Diamond Colour: {getValue(item?.diamond_colour)}
            </h5>
            <h5 className="fw-normal">
              Diamond Clarity: {getValue(item.diamond_clarity)}
            </h5>
            <h5 className="fw-normal">
              Diamond Shape/Cut: {getValue(item.diamond_shape)}
            </h5>
            <h5 className="fw-normal">
              Diamond Laser Inscription:{" "}
              {getValue(item.diamond_laser_inscription)}
            </h5>
            <h5 className="fw-normal">
              Diamond Brand: {getValue(item.diamond_brand)}
            </h5>
          </>
        )}

        {type === "Jewel" && (
          <>
            <h5 className="fw-normal">
              Jewel Colour: {getValue(item.jewel_colour)}
            </h5>
            <h5 className="fw-normal">
              Jewel Metal: {getValue(item.jewel_metal)}
            </h5>
            <h5 className="fw-normal">
              Jewel Materials: {getValue(item.jewel_materials)}
            </h5>
            <h5 className="fw-normal">
              Jewel Serial Number/Jewel Laser Inscription:{" "}
              {getValue(item.jewel_laser_inscription)}
            </h5>
            <h5 className="fw-normal">
              Jewel Brand: {getValue(item.jewel_brand)}
            </h5>
          </>
        )}
        {type === "Watch" && (
          <>
            <h5 className="fw-normal">
              Watch Metal: {getValue(item.watch_metal)}
            </h5>
            <h5 className="fw-normal">
              Watch Serial Number: {getValue(item.watch_serial_number)}
            </h5>
            <h5 className="fw-normal">
              Watch Brand: {getValue(item.watch_brand)}
            </h5>
          </>
        )}
        <h5 className="fw-normal">
          Certificate Number, Valuation Number, or Other Unique Number assigned
          to item: {getValue(item.certificate_number)}
        </h5>
        <h5 className="fw-normal">
          Issuer of Certificate, Valuation and Other Unique Number:{" "}
          {getValue(item.issuer_of_certificate)}
        </h5>
        <h5 className="fw-normal">
          Country of the Issuer of Certificate,Valuation and Other Unique
          Number: {getValue(item.country_of_certificate_issuer)}
        </h5>

        <h5 className="fw-normal">Value of Item: {getValue(value)}</h5>
      </div>
      <div className="my-1">
        {item?.primaryPhoto && (
          <>
            <h5 className="fw-normal">Jewel Photo:</h5>

            <ImgContainer className="my-2">
              <img
                src={`data:${
                  item?.primaryPhoto?.contentType
                };base64,${arrayBufferToBase64(item?.primaryPhoto?.data.data)}`}
                className="img-fluid"
              />
            </ImgContainer>
            <br />
            <br />
          </>
        )}
      </div>
      <div className="my-1">
        {item?.files?.length > 0 && (
          <h5 className="fw-normal">Uploaded Documents:</h5>
        )}
        <ImgContainer className="my-2">
          {item?.files?.map((file) => (
            <div>
            <a href={`${API}/file/download/${file.id}`} download>
              {file.name}
            </a>
            <button style={{marginLeft: "0.5rem"}} onClick={() => {
              item.files = item.files.filter(x => x.id !== file.id);
              handleChange();
            }}>delete</button>
            </div>
          ))}
        </ImgContainer>
      </div>
    </Container>
  );
}

export default ItemCard;
