import { useEffect, useState } from "react";
import { getCMSEndpoint } from "../../helper";
import { useParams } from "react-router-dom";

import "./BlogIndex.css";

function BlogIndex() {
  var [posts, setPosts] = useState(null);

  const [error, setError] = useState("");

  function handleError(res) {
    setError(`Encountered error while loading posts: ${res.data.error}`);
  }
  useEffect(() => {
    getCMSEndpoint("posts?pagination[limit]=-1&pagination[start]=0")
      .then((res) => {
        if (res.data.error) {
          handleError(error.response);
        } else {
          setPosts(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error.response);
      });
  }, []);

  return (
    <div
      className="p-3 p-md-5"
      style={{
        color: "black",
        width: "1100px",
        maxWidth: "95%",
        margin: "auto",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2 className="fw-normal" style={{ marginBottom: "2rem" }}>
          The Lapitas Blog
        </h2>
      </div>

      <h3 id="posts-heading">Posts</h3>
      {posts == null ? (
        <>
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <div>Loading posts...</div>
          )}
        </>
      ) : (
        <ul>
          {posts.map((post) => {
            return (
              <li key={post.id}>
                <a href={`/blog/post/${post.id}`}>{post.attributes.title}</a> ({post.attributes.date_published})
              </li>
            );
          })}
        </ul>
      )}

      <p className="fw-normal mb-3"></p>
    </div>
  );
}

export default BlogIndex;
