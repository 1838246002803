const colors = {
  white: "#ffffff",
  lightBlack: "#333333",
  black: "#000000",
  gray: "#38383b",
  lightGray: "#e8e6e7",
  red: "#f60000",
  borderColor: "#aaaaaa",
  darkGray: "#dfdfdf",
};

export default colors;
