import React, { useState } from "react";
import CustomButton from "../Common/Button";
import Input from "../Common/Input";
import axios from "axios";
import { API } from "../../util/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import TextArea from "../Common/TextArea";
import LostFound from "../../images/lapitas-lost-and-found-concierge.jpg";

function LostAndFound() {
  return (
    <div style={{ margin: "2rem" }}>
      <div
        className="p-md-5  px-2"
        style={{
          textAlign: "center",
          margin: "auto",
        }}
      >
        <h2
          className="fw-normal secondary-font"
          style={{ marginBottom: "1rem" }}
        >
          Lost & Found Concierge Service
        </h2>{" "}
        <br />
        <img
          src={LostFound}
          className="img-600px-max"
          style={{ marginBottom: "2rem" }}
        />
        <br />
        <div
          className="px-md-5 my-2"
          style={{ width: "1000px", maxWidth: "100%", margin: "auto" }}
        >
          <h3 className="fw-normal">
            Found an item and want to return it home? Thank you! Book your FREE
            and secure pick up & delivery service.
          </h3>
          <p className="mt-4" style={{ marginBottom: "3rem" }}>
            Thank you for being a super star! Simply fill in the form below and
            we will contact you to organise a free secure pick up and delivery
            service to the owner. Thanks to you, you’ve made the world a nicer
            place to live in. Need our direct email address? found@lapitas.org
          </p>
          <RightForm />
        </div>
      </div>

      <div
        style={{
          color: "black",
          width: "1000px",
          maxWidth: "100%",
          margin: "auto",
          marginBottom: "5rem"
        }}
      >
        <div className="px-md-5">
          <h5 className="fw-medium mb-4">
            How does the Lapitas: Lost & Found work?
          </h5>
          <p className="mb-4">
            We love to help and want to make it as easy as possible to reunite
            precious items to their owners. If you’ve found an item, we make
            sure it returns home. Simply fill in the form above and Lapitas
            arranges FREE secure pick up and delivery of the item (at zero cost
            to the finder and the owner!) Lapitas also gives a special thank you
            reward to the finder for being a beautiful human being (Lapitas
            picks up the tab for the reward so the owner doesn’t have to!)
          </p>

          <h5 className="fw-medium mb-4">Will I get a reward?</h5>
          <p className="mb-4">
            Most people that hand in lost or stolen pieces don’t want a reward
            and are happy to receive good karma. But also Lapitas believes that
            good deeds should be rewarded. Lapitas gives you a reward on behalf
            of the owner (and behalf of all Lapitas Members!) for being an
            amazing human being. This is a community that cares about returning
            precious items home and Lapitas loves to facilitate the flow of lost
            and stolen items back to their distressed owners.
          </p>

          <h5 className="fw-medium mb-4">Do I have to pay for shipping?</h5>
          <p className="mb-4">
            Shipping is free! Lapitas takes care of the costs for the shipping.
            We want to make is as easy as possible to reunite items to their
            owners so both the finder and the owner do not pay any shipping
            fees. We use secure shipping services that require ID and signature
            on delivery.
          </p>

          <h5 className="fw-medium mb-4">When will you pick up the item?</h5>
          <p className="mb-4">
            Pick a day and time that suits you! We work around your schedule to
            make it as easy as possible.
          </p>
        </div>
      </div>
    </div>
  );
}

const initialData = {
  name: "",
  email: "",
  item_number: "",
  message: "",
};
const RightForm = () => {
  const history = useHistory();
  const [values, setValues] = useState(initialData);

  const [error, setError] = useState("");

  const handleSubmit = () => {
    setError(null);
    const data = { ...values };
    let form_data = new FormData();

    for (var key in data) {
      if (data[key] === Object(data) || Array.isArray(data[key])) {
        for (var i in data[key]) {
          form_data.append(`${key}[${i}]`, data[key][i] || "");
        }
        if (Array.isArray(data[key]) && data[key].length === 0) {
          form_data.append(`${key}[0]`, "");
        }
      } else {
        form_data.append(key, data[key]);
      }
    }

    toast.success("Email Received!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setValues(initialData);
    history.push("/");
    axios
      .post(`${API}/founditem/sendEmail`, values)
      .then((res) => {})
      .catch((error) => {
        setError(error.response.data.error);
      });
  };

  const handleChange = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const inputStyle = { background: "white", border: "1px solid" };
  return (
    <>
      <div className="px-3  px-sm-3">
        <form
          onSubmit={handleSubmit}
          style={{ width: "800px", maxWidth: "90%", margin: "auto" }}
        >
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="text"
              name="name"
              label="NAME"
              onChange={(e) => handleChange("name", e)}
              value={values.firstname}
            />
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="email"
              name="email"
              label="EMAIL"
              onChange={(e) => handleChange("email", e)}
              value={values.contact_email}
            />
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="text"
              name="number"
              label="UNIQUE NUMBER OF FOUND ITEM"
              onChange={(e) => handleChange("item_number", e)}
              value={values.contact_email}
            />
          </div>

          <div style={{ marginBottom: "1rem", fontSize: "1.5rem" }}>
            <TextArea
              style={inputStyle}
              labelAlignment="left"
              name="message"
              label="MESSAGE"
              onChange={(e) => handleChange("message", e)}
              value={values.found_item_description}
            />
          </div>

          <CustomButton
            type="submit"
            style={{ alignSelf: "center", width: "100%" }}
            onClick={handleSubmit}
          >
            Send
          </CustomButton>
        </form>
      </div>
    </>
  );
};
export default LostAndFound;
