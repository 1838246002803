import React, { useContext, useEffect, useState } from "react";
import colors from "../../util/colors";
import SearchIcon from "@material-ui/icons/Search";
import styled from "@emotion/styled";
import { getValue } from "../../helper";
import axios from "axios";
import { API } from "../../util/constant";
import { AppContext } from "../../App";
import CustomButton from "../Common/Button";
import { useHistory, useLocation } from "react-router";

import stolen from "../../images/stolen icon.png";
import registered from "../../images/lapitas-registered icon.jpg";
import forsale from "../../images/forsale icon.png";

const SearchInput = styled.input`
  border: none;

  &:focus {
    background-color: transparent;
    border-color: none;
    box-shadow: none;
  }
`;

const SearchInputContainer = styled.div`
  // background: ${colors.darkGray};
  border: 2px solid ${colors.black};
  padding: 0.5rem;
  align-items: center;
  margin: 0.5rem auto;
  width: 300px !important;
  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    border: 1px transparent solid;
  }
  &:focus-within {
    border-color: none;
    box-shadow: none;
    outline: none;

    &:after {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      bottom: -5px;
      right: -5px;
      border: 1px ${colors.black} solid;
    }
  }
`;

const m = styled.span`
  font-weight: medium;
`;

const getImageSource = (status) => {
  switch (status) {
    case "Registered":
      return registered;
    case "Lost":
      return stolen;
    case "For Sale":
      return forsale;
    default:
      return forsale;
  }
};

const Card = ({ item }) => {
  const type = item.item_type;
  const history = useHistory();
  return (
    <div className="my-3">
      <div
        className="align-items-center"
        style={{ color: "black", marginBottom: "4rem", display: "flex", flexDirection: "row" }}
      >
        <div
          className="text-center"
          style={{ width: "180px !important", marginRight: "4rem" }}
        >
          <img
            style={{ width: "180px" }}
            src={getImageSource(item.status)}
            className="img-fluid mb-3"
          />
        </div>
        <div className="col-md-8">
          <div className="mb-3">
            <h5>Item Type: {getValue(item.item_type)} </h5>
            <h5>Status: {getValue(item.status)} </h5>
            {type === "Diamond" && (
              <>
                <h5>Diamond Colour: {getValue(item?.diamond_colour)}</h5>
                <h5>Diamond Clarity: {getValue(item.diamond_clarity)}</h5>
                <h5>Diamond Shape/Cut: {getValue(item.diamond_shape)}</h5>
                <h5>
                  Diamond Laser Inscription:{" "}
                  {getValue(item.diamond_laser_inscription)}
                </h5>
                <h5>Diamond Brand: {getValue(item.diamond_brand)}</h5>
              </>
            )}

            {type === "Jewel" && (
              <>
                <h5>Jewel Colour: {getValue(item.jewel_colour)}</h5>
                <h5>Jewel Metal: {getValue(item.jewel_metal)}</h5>
                <h5>Jewel Materials: {getValue(item.jewel_materials)}</h5>
                <h5>
                  Jewel Serial Number/Jewel Laser Inscription:{" "}
                  {getValue(item.jewel_laser_inscription)}
                </h5>
                <h5>Jewel Brand: {getValue(item.jewel_brand)}</h5>
              </>
            )}

            {type === "Watch" && (
              <>
                <h5>Watch Metal: {getValue(item.watch_metal)}</h5>
                <h5>
                  Watch Serial Number: {getValue(item.watch_serial_number)}
                </h5>
                <h5>Watch Brand: {getValue(item.watch_brand)}</h5>
              </>
            )}

            <h5>
              Serial / Certificate Number: {getValue(item.certificate_number)}
            </h5>
            <h5>Certificate Brand: {getValue(item.issuer_of_certificate)}</h5>

            {item?.primaryPhoto && (
              <div
                className="my-3"
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`/search-list/${item._id}`)} // TODO: fix button
              >
                <h5 style={{ textDecoration: "underline" }}>View image</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function SearchScreen({ props }) {
  const location = useLocation();
  const [text, setText] = useState(location?.state?.text || "");
  const [data, setData] = useState([]);
  var contextData = useContext(AppContext);
  const config = {
    headers: { Authorization: `Bearer ${contextData.token}` },
  };

  const getDataBySearch = () => {
    axios
      .get(`${API}/search/${text}`, config)
      .then((res) => setData(res.data))
      .catch((err) => {
        setData([]);
        console.log({ err });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getDataBySearch();
  };

  return (
    <div style={{ margin: "2rem" }}>
      <div className="container ">
        <h2 className="fw-normal text-center search-heading">
          Search Database
        </h2>
        <h5 className="my-3 text-center">
          Check the ownership status of diamonds, jewels & watches.
        </h5>

        <h5
          className="text-center"
          style={{
            marginTop: "3rem",
            fontWeight: "normal",
            color: "#444",
            fontSize: "18px",
          }}
        >
          SEARCH THE ITEM NUMBER
        </h5>
        <form
          className="text-center"
          onSubmit={handleSubmit}
          style={{ marginBottom: "8rem" }}
        >
          <SearchInputContainer className="input-group search-input">
            <SearchIcon />
            <SearchInput
              value={text}
              onChange={(e) => setText(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Enter Number"
            />
          </SearchInputContainer>
          <CustomButton
            type="submit"
            onClick={handleSubmit}
            style={{ marginTop: "0.2rem" }}
          >
            Search
          </CustomButton>
          <div className="my-4 mx-5">
            <h5 className="my-4 text-center">
              SEARCH USING: Laser inscription, certificate number, engraving
              marks, valuation number, serial number, case number or
              identification number.
            </h5>
          </div>
        </form>

        {data && data.length > 0 ? (
          <h3 style={{ marginBottom: "2rem" }}>Search Results </h3>
        ) : (
          <></>
        )}

        {data && data.length > 0 ? (
          data.map((item) => <Card key={item._id} item={item} />)
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default SearchScreen;
