import React, { useState, useEffect } from "react";
import CustomButton from "../Common/Button";
import Input from "../Common/Input";
import axios from "axios";
import { API } from "../../util/constant";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import TextArea from "../Common/TextArea";
import queryString from "query-string";

function ResetPassword() {
  return (
    <div>
      <div
        className="p-md-5  px-2"
        style={{
          textAlign: "center",
          margin: "auto",
        }}
      >
        <h2
          className="fw-normal secondary-font"
          style={{ marginBottom: "1rem" }}
        >
          Reset Password
        </h2>
        <div
          className="px-md-5 my-2"
          style={{ width: "800px", maxWidth: "100%", margin: "auto" }}
        >
          <RightForm />
        </div>
      </div>
    </div>
  );
}

const initialData = {
  new_password: "",
  confirm_new_password: ""
};

const RightForm = () => {
  const history = useHistory();
  const [values, setValues] = useState(initialData);

  const [error, setError] = useState("");

  const params = queryString.parse(window.location.search);
  var token = params["token"];
  useEffect(() => {
    if (token == null || token == undefined) {
      token = "";
      setError("Invalid reset token");
      toast.error("Invalid reset token", {
        toastId: "invalid reset token",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      history.push("/");
    }

    axios
      .get(`${API}/validate-reset-token`, { params: { token: token } })
      .then((res) => {
        if (res.data.valid) {
          return;
        }

        toast.error(res.data.reason, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        history.push("/");
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    const data = { ...values };
    let form_data = new FormData();

    for (var key in data) {
      if (data[key] === Object(data) || Array.isArray(data[key])) {
        for (var i in data[key]) {
          form_data.append(`${key}[${i}]`, data[key][i] || "");
        }
        if (Array.isArray(data[key]) && data[key].length === 0) {
          form_data.append(`${key}[0]`, "");
        }
      } else {
        form_data.append(key, data[key]);
      }
    }

    setValues(initialData);
    axios
      .post(`${API}/reset-password`, {token, ...values})
      .then((_res) => {
        toast.success("Password reset successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push("/login");
      })
      .catch((error) => {
        if (error.response.data.redirect) {
          toast.error(error.response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push("/");
        } else {
          setError(error.response.data.error);
        }
      });
  };

  const handleChange = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const inputStyle = { background: "white", border: "1px solid" };
  return (
    <>
      <div className="px-3  px-sm-3">
        <form
          onSubmit={handleSubmit}
          style={{ width: "800px", maxWidth: "90%", margin: "auto" }}
        >
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="password"
              name="new_password"
              label="NEW PASSWORD"
              onChange={(e) => handleChange("new_password", e)}
              value={values.new_password}
            />
          </div>

          <div style={{ marginBottom: "1.5rem" }}>
            <Input
              style={inputStyle}
              labelAlignment="left"
              type="password"
              name="confirm_new_password"
              label="CONFIRM PASSWORD"
              onChange={(e) => handleChange("confirm_new_password", e)}
              value={values.confirm_new_password}
            />
          </div>

          <CustomButton
            type="submit"
            style={{ alignSelf: "center", width: "100px" }}
            onClick={handleSubmit}
          >
            Reset
          </CustomButton>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
